
body {
  font-weight: 400;
}


.input {
  width: 100%;
  min-width: 0px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
  transition-duration: 200ms;
  font-size: 0.875rem;
  -webkit-padding-start: 0.75rem;
  padding-inline-start: 0.75rem;
  -webkit-padding-end: 0.75rem;
  padding-inline-end: 0.75rem;
  height: 40px;
  border-radius: 8px;
  border: 2px solid;
  border-color: transparent;
  background: rgba(255, 255, 255, 0.04);
  font-weight: 500;
}

.btn {
  padding: 0 20px;
  height: 40px;
  background-color: #3182ce;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border-radius: 10px;
}

.input:focus {
  border-color: #3182ce;
}

.inputError {
  border-color:#FC8181
}

.ReactModal__Overlay--after-open {
  z-index: 999999 !important;
}


.errorWrapper {
  border: 1px solid #FC8181 !important;
  border-radius: 20px;
}
